import React from "react"
import { Link } from "gatsby"

import Banner from "../../components/banner"
import Seo from "../../components/seo"
import Layout from "../../components/layout"

function FailurePage() {
  return (
    <Layout>
      <Seo title="Complete Your Membership" />
      <Banner label="Join the BSSR">Registration</Banner>

      <div className="container mx-auto py-3 lg:py-12">
        <h2 className="text-3xl font-bold mb-4">
          Your Membership Was Not Activated
        </h2>

        <p>
          Your payment was cancelled and we were unable to activate your
          membership at this time.
          <br />
          Please return to your account and try again.
        </p>

        <Link
          to="/account"
          className="inline-block text-center mb-2 w-auto mt-8 hover:bg-indigo-800 focus:bg-blue-400 text-white font-semibold bg-indigo-900 rounded-lg px-4 py-3"
        >
          Go back to your account
        </Link>
      </div>
    </Layout>
  )
}

export default FailurePage
